<div class="business-row" *ngIf="business">
    <div class="business-row-name" *ngIf="business.businessName">{{ business.businessName }}</div>
    <div class="business-row-type" *ngIf="business.businessType">{{ business.businessType }}</div>
    <div class="business-row-contact" *ngIf="business.businessPhone">
        <span class="business-row-phone" *ngIf="business.businessPhone || business.businessEmail">
            <i class="material-icons phone-icon">phone</i>
            <a href="tel:{{ business.businessPhone }}">
                {{ business.businessPhone }}
            </a>
        </span>
        <span class="business-row-email" *ngIf="business.businessEmail">
            <i class="material-icons mail-icon">mail</i>
            <a href="mailto:{{ business.businessEmail }}">{{ business.businessEmail }}</a>
        </span>
    </div>

    <span class="business-row-website" *ngIf="business.businessWebsite">
        <i class="material-icons">language</i>
        <a href="http://{{business.businessWebsite}}" target="_blank">{{ business.businessWebsite }}</a>
    </span>
</div>
