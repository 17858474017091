<mat-card *ngIf="directory" class="resident-profile resident-profile__container">

    <mat-card-title>
      <h2>{{ directory.streetNumber +  ' ' + directory.streetName }}</h2>
    </mat-card-title>
  
    <mat-card-content *ngIf="directory">
      <h4>Primary Contact</h4>
      <mat-form-field color="accent">
        <mat-label>Name</mat-label>
        <input matInput type="text" value="{{ directory.name1 }}" [disabled]="!isAdmin">
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Phone Number</mat-label>
        <input matInput type="text" value="{{ directory.phone1 }}" [disabled]="!isAdmin" *ngIf="directory.sharePhone">
        <input matInput type="text" value="Do Not Share" [disabled]="!isAdmin" *ngIf="!directory.sharePhone">
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>E-mail</mat-label>
        <input matInput type="text" value="{{ directory.email1 }}" [disabled]="!isAdmin" *ngIf="directory.shareEmail">
        <input matInput type="text" value="Do Not Share" [disabled]="!isAdmin" *ngIf="!directory.shareEmail">
      </mat-form-field>
  
      <br>
  
      <ng-container *ngIf="directory.name2 || directory.phone2 || directory.email2">
        <h4>Backup Contact</h4>
        <mat-form-field color="accent">
          <mat-label>Name</mat-label>
          <input matInput type="text" value="{{ directory.name2 }}" [disabled]="!isAdmin">
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" value="{{ directory.phone2 }}" [disabled]="!isAdmin" *ngIf="directory.sharePhone">
          <input matInput type="text" value="Do Not Share" [disabled]="!isAdmin" *ngIf="!directory.sharePhone">
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label>E-mail</mat-label>
          <input matInput type="text" value="{{ directory.email2 }}" [disabled]="!isAdmin" *ngIf="directory.shareEmail">
          <input matInput type="text" value="Do Not Share" [disabled]="!isAdmin" *ngIf="!directory.shareEmail">
        </mat-form-field>
      </ng-container>
  
      <br>
  
      <ng-container *ngIf="directory.businessName">
        <h4>Business</h4>
        <mat-form-field color="accent">
          <mat-label>Business Name</mat-label>
          <input matInput type="text" value="{{ directory.businessName }}" [disabled]="!isAdmin">
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label>Business Phone</mat-label>
          <input matInput type="text" value="{{ directory.businessPhone }}" [disabled]="!isAdmin">
        </mat-form-field>
        <mat-form-field color="accent">
          <mat-label>Business E-mail</mat-label>
          <input matInput type="text" value="{{ directory.businessEmail }}" [disabled]="!isAdmin">
        </mat-form-field>
      </ng-container>
  
      <br>
  
    </mat-card-content>
  
    <mat-card-actions *ngIf="isAdmin" class="profile-actions">
      <button mat-raised-button color="accent">Update</button>
    </mat-card-actions>
  
  </mat-card>
  