import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bpa-logo',
  templateUrl: './bpa-logo.component.html',
  styleUrls: ['./bpa-logo.component.scss']
})
export class BpaLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
