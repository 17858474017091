<div class="directory-row">
    <div class="directory-row-address">
        <span class="street-number" *ngIf="directory.streetNumber">{{ directory.streetNumber }}</span>
        <span class="street-name" *ngIf="directory.streetName"> {{ directory.streetName }}</span>
    </div>
    <div class="directory-row-names">
        <div class="contact" *ngIf="directory.name1">
            <div class="contact-name">{{ directory.name1 }}</div>
            <span class="contact-phone" *ngIf="directory.phone1">
                <i class="material-icons phone-icon">phone</i>
                <a href="tel:{{ directory.phone1 }}">
                    {{ directory.phone1 }}
                </a>
            </span>
            <span class="contact-email" *ngIf="directory.email1">
                <i class="material-icons mail-icon">mail</i>
                <a href="mailto:{{ directory.email1 }}">{{ directory.email1 }}</a>
            </span>
        </div>
        <div class="contact" *ngIf="directory.name2">
            <div class="contact-name">{{ directory.name2 }}</div>
            <span class="contact-phone" *ngIf="directory.phone2">
                <i class="material-icons phone-icon">phone</i>
                <a href="tel:{{ directory.phone2 }}">
                    {{ directory.phone2 }}
                </a>
            </span>
            <span class="contact-email" *ngIf="directory.email2">
                <i class="material-icons mail-icon">mail</i>
                <a href="mailto:{{ directory.email2 }}">{{ directory.email2 }}</a>
            </span>
        </div>
    </div>
    <div class="directory-row-actions" *ngIf="authService.user | async as user">
        <button mat-button color="accent" (click)="claim()" *ngIf="!user.claimedProperty && !directory.claimed" class="claim-button">Claim</button>
        <button mat-button color="primary" (click)="update()" *ngIf="user.isAdmin" class="update-button">Update</button>
        <button mat-button color="warn" (click)="reset()" *ngIf="user.isAdmin" class="reset-button">Reset</button>
    </div>
</div>
