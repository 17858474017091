<div class="registration">

  <div class="registration__container registration__title">
    <h1 class="registration__title--text">Resident Profile</h1>
  </div>

  <mat-divider></mat-divider>

  <div class="registration__container">
    <div [formGroup]="registrationForm" class="registration__form">

      <div class="registration__container">

        <div class="registration__col">
          <mat-form-field class="registration__form-field">
            <mat-label>Display Name</mat-label>
            <input matInput formControlName="displayName" type="text">
          </mat-form-field>
          <mat-form-field class="registration__form-field">
            <mat-label>Profile Picture URL</mat-label>
            <input matInput formControlName="photoURL" type="text">
          </mat-form-field>
          <mat-form-field class="registration__form-field">
            <mat-label>Login E-mail</mat-label>
            <input matInput formControlName="email" type="email" autocomplete="username email">
          </mat-form-field>
          <mat-form-field class="registration__form-field">
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" type="password">
          </mat-form-field>
        </div>
      </div>

      <button mat-raised-button color="primary" *ngIf="registrationForm.valid" (click)="onSubmit()">Create</button>
    </div>
  </div>

</div>