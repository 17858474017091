import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Directory } from '../../models/directory';

@Component({
  selector: 'app-directory-row',
  templateUrl: './directory-row.component.html',
  styleUrls: ['./directory-row.component.scss']
})
export class DirectoryRowComponent implements OnInit {
  @Input() directory: Directory;
  @Output() claimProfile: EventEmitter<any> = new EventEmitter();
  @Output() resetProperty: EventEmitter<any> = new EventEmitter();
  @Output() updateProfile: EventEmitter<any> = new EventEmitter();
  constructor(
    public authService: AuthService
  ) { }
  claim() {
    this.claimProfile.emit(this.directory);
  }
  reset() {
    this.resetProperty.emit({
      property: this.directory.id,
      user: this.directory.user.id
    });
  }
  update() {
    this.updateProfile.emit(this.directory);
  }
  ngOnInit(): void {
  }

}
