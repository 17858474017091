import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Directory } from '../../models/directory';

@Component({
  selector: 'app-view-resident-profile',
  templateUrl: './view-resident-profile.component.html',
  styleUrls: ['./view-resident-profile.component.scss']
})
export class ViewResidentProfileComponent implements OnInit {
  directory: Directory;
  isAdmin: boolean;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.directory = this.data.directory;
    this.isAdmin = this.data.isAdmin;
  }

}
