<div class="resident-profile">
  <div [formGroup]="parent" class="resident-profile__form">

    <h3 class="resident-profile__header">Resident</h3>
    <div class="resident-profile__container">
  
      <div>
        <mat-form-field appearance="fill" class="resident-profile__form-field" color="accent">
          <mat-label>Resident Name</mat-label>
          <input matInput formControlName="name1" type="text">
        </mat-form-field>
      </div>
  
      <div>
        <mat-form-field appearance="fill" class="resident-profile__form-field" color="accent">
          <mat-label>E-mail Address</mat-label>
          <input matInput formControlName="email1" type="email">
        </mat-form-field>
      </div>
  
      <div>
        <mat-form-field appearance="fill" class="resident-profile__form-field" color="accent">
          <mat-label>Phone Number</mat-label>
          <input matInput formControlName="phone1" type="text">
        </mat-form-field>
      </div>
  
    </div>
  
    <h3 class="resident-profile__header">Resident</h3>
    <div class="resident-profile__container">
  
      <div>
        <mat-form-field appearance="fill" class="resident-profile__form-field" color="accent">
          <mat-label>Resident Name</mat-label>
          <input matInput formControlName="name2" type="text">
        </mat-form-field>
      </div>
  
      <div>
        <mat-form-field appearance="fill" class="resident-profile__form-field" color="accent">
          <mat-label>E-mail Address</mat-label>
          <input matInput formControlName="email2" type="email">
        </mat-form-field>
      </div>
  
      <div>
        <mat-form-field appearance="fill" class="resident-profile__form-field" color="accent">
          <mat-label>Phone Number</mat-label>
          <input matInput formControlName="phone2" type="text">
        </mat-form-field>
      </div>
  
    </div>
  
    <h3 class="resident-profile__header">Business</h3>
    <div class="resident-profile__container">
      <div>
        <mat-form-field appearance="fill" class="resident-profile__form-field" color="accent">
          <mat-label>Business Name</mat-label>
          <input matInput formControlName="businessName" type="text">
        </mat-form-field>
      </div>
  
      <div>
        <mat-form-field appearance="fill" class="resident-profile__form-field" color="accent">
          <mat-label>Business Type</mat-label>
          <input matInput formControlName="businessType" type="text">
        </mat-form-field>
      </div>
    </div>
  
    <div class="resident-profile__container">
  
      <div>
        <mat-form-field appearance="fill" class="resident-profile__form-field" color="accent">
          <mat-label>Business Phone</mat-label>
          <input matInput formControlName="businessPhone" type="text">
        </mat-form-field>
      </div>
  
      <div>
        <mat-form-field appearance="fill" class="resident-profile__form-field" color="accent">
          <mat-label>Business E-mail</mat-label>
          <input matInput formControlName="businessEmail" type="text">
        </mat-form-field>
      </div>
  
    </div>
  
    <br>
  
    <h3 class="resident-profile__header">Contact Preferences</h3>
    <div class="resident-profile__container">
      <mat-checkbox formControlName="shareEmail" class="resident-profile__checkboxes">Share E-mail</mat-checkbox>
      <mat-checkbox formControlName="sharePhone" class="resident-profile__checkboxes">Share Phone</mat-checkbox>
    </div>

    <button mat-raised-button color="accent" (click)="doUpdate()" class="resident-profile__button">Update</button>
  </div>
</div>
