<div class="resident-profile">

    <div class="resident-profile__container resident-profile__title">
      <h1 class="resident-profile__title">Resident Profile</h1>
    </div>
  
    <ng-container *ngIf="authService.user | async as user">
      <h3 *ngIf="user.claimedProperty==null" class="resident-profile__message">
        You must first claim a property, then you may complete your profile
      </h3>
    </ng-container>
    <div *ngIf="resident | async as res">
      <form [formGroup]="residentForm" *ngIf="res">
        <resident-profile [parent]="residentForm" [parentValues]="res" (parentForm)="updateDirectory($event, res)"></resident-profile>
      </form>
    </div>
  
  </div>
  
  