<mat-toolbar color="primary" class="hoa-navbar">
  <mat-toolbar-row class="hoa-navbar__container">
    <!-- <a routerLink="/" class="hoa-navbar__directory-title">{{ name }}</a> -->
    <button mat-button class="hoa-navbar__mobileList" (click)="mobileNavSwap()">
      <i class="material-icons">menu</i>
    </button>
    <app-bpa-logo></app-bpa-logo>
    <ng-container *ngIf="authService.user | async as user">
      <mat-nav-list class="hoa-navbar__navlist hoa-navbar__text">
        <!-- <a
          *ngIf="user.isAdmin"
          mat-list-item
          matLine
          routerLink="/admin"
          class="hoa-navbar__navlist--link"
        >Administration</a> -->
        <a
          *ngFor="let item of navList"
          mat-list-item
          matLine
          routerLink="/{{ item.link }}"
          class="hoa-navbar__navlist--link"
        >{{ item.key }}</a>
      </mat-nav-list>
      <div class="hoa-navbar__spacer"></div>
      <a routerLink="/resident" class="hoa-navbar__display-name hoa-navbar__text">{{ user.displayName || user.email }}</a>
      <img class="hoa-navbar__profile-pic" *ngIf="user.photoURL && user.photoURL !== 'None Provided'" src="{{ user.photoURL }}" alt="Profile Picture">
      <p class="hoa-navbar__signout hoa-navbar__text" (click)="signOut()">Sign Out</p>
    </ng-container>
    <div class="hoa-navbar__spacer" *ngIf="!loggedIn"></div>
    <a mat-stroked-button routerLink="/auth" class="hoa-navbar__login hoa-navbar__text" *ngIf="!loggedIn">Login</a>
  </mat-toolbar-row>
</mat-toolbar>
<div class="hoa-navbar__menu" *ngIf="mobileNav">
  <a
    *ngFor="let item of navList"
    mat-list-item
    matLine
    routerLink="/{{ item.link }}"
    class="hoa-navbar__menu--link"
    (click)="mobileNavSwap()"
  >{{ item.key }}</a>
</div>
  