import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  onSubmit() {
    this.dialogRef.close({ type: 'email', loginForm: this.loginForm.value });
  }
  onSocialMedia(type: string) {
    this.dialogRef.close({ socialMedia: type });
  }
  onReset() {
    this.dialogRef.close({ type: 'reset' });
  }
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.loginForm = this.data.loginForm;
  }

}
