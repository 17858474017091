import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Directory } from '../../models/directory';

@Component({
  selector: 'resident-profile',
  templateUrl: './resident-profile.component.html',
  styleUrls: ['./resident-profile.component.scss']
})
export class ResidentProfileComponent implements OnInit {
  @Input()
  parent: FormGroup;
  @Input()
  parentValues: Directory;
  @Output()
  parentForm: EventEmitter<Directory> = new EventEmitter<Directory>();
  constructor() { }
  doUpdate() {
    this.parentForm.emit(this.parent.value);
  }
  ngOnInit(): void {
    this.parent.patchValue(this.parentValues[0]);
  }

}
