import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'app-admin-row',
  templateUrl: './admin-row.component.html',
  styleUrls: ['./admin-row.component.scss']
})
export class AdminRowComponent implements OnInit {
  @Input() admin: User;
  @Output() approve: EventEmitter<string> = new EventEmitter();
  constructor() { }
  approveResident() {
    this.approve.emit(this.admin.id);
  }
  ngOnInit(): void {
    // console.log(this.admin);
  }

}
