import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  unsubscribe$: Subject<any> = new Subject<any>();
  // The user management action to be completed
  mode: string;
  // Just a code Firebase uses to prove that
  // this is a real password reset.
  actionCode: string;

  oldPassword: string;
  newPassword: string;
  confirmPassword: string;

  actionCodeChecked: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) { }
  /**
   * Attempt to confirm the password reset with firebase and
   * navigate user back to home.
   */
  handleResetPassword() {
    if (this.newPassword !== this.confirmPassword) {
      alert('New Password and Confirm Password do not match');
      return;
    }
    // Save the new password.
    this.authService.getAuth().confirmPasswordReset(
      this.actionCode,
      this.newPassword
    ).then(resp => {
      // Password reset has been confirmed and new password updated.
      alert('New password has been saved');
      this.router.navigate(['/auth/login']); }).catch(e => {
      // Error occurred during confirmation. The code might have
      // expired or the password is too weak. alert(e);
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((params) => {
        if (!params) {
          this.router.navigate(['/home']);
        }
        this.mode = params['mode'];
        this.actionCode = params['oobCode'];
        console.log({
          mode: this.mode,
          actionCode: this.actionCode
        });
    });
  }

}
