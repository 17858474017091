import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './directory/containers/admin/admin.component';
import { BerkeleyComponent } from './berkeley/berkeley.component';
import { AuthComponent } from './core/containers/auth/auth.component';
import { ApprovedGuard } from './core/guards/approved.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { BusinessComponent } from './directory/containers/business/business.component';
import { DirectoryComponent } from './directory/containers/directory/directory.component';
import { ResidentComponent } from './directory/containers/resident/resident.component';
import { ProfileGuard } from './directory/guards/profile.guard';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard, ApprovedGuard, AdminGuard] },
  {
    path: 'directory',
    component: DirectoryComponent,
    canActivate: [AuthGuard, ApprovedGuard]
  },
  { path: 'auth', component: AuthComponent },
  { path: 'resident', component: ResidentComponent, canActivate: [AuthGuard, ApprovedGuard, ProfileGuard] },
  { path: 'business', component: BusinessComponent, canActivate: [AuthGuard, ApprovedGuard] },
  { path: '', component: BerkeleyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
