<div [formGroup]="parent" class="new-business__form">
    <h3>New Business</h3>
  
    <mat-divider></mat-divider>
  
    <br>
  
    <mat-form-field>
      <mat-label>Business Name</mat-label>
      <input matInput formControlName="businessName" type="text">
    </mat-form-field>
  
    <mat-form-field>
      <mat-label>Business Type</mat-label>
      <input matInput formControlName="businessType" type="text">
    </mat-form-field>
  
    <mat-form-field>
      <mat-label>Business Phone</mat-label>
      <input matInput formControlName="businessPhone" type="text">
    </mat-form-field>
  
    <mat-form-field>
      <mat-label>Business E-mail</mat-label>
      <input matInput formControlName="businessEmail" type="text">
    </mat-form-field>
  
    <mat-form-field>
      <mat-label>Business Website</mat-label>
      <input matInput formControlName="businessWebsite" type="text">
    </mat-form-field>
  
    <button mat-raised-button color="accent" *ngIf="parent.valid" (click)="addNewBusiness()">Add New</button>
  
  </div>
  