import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Business } from '../models/business';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  private businessCollection: AngularFirestoreCollection<Business>;
  private businessDocument: AngularFirestoreDocument<Business>;
  businesses$: Observable<Business[]>;
  business$: Observable<Business>;
  constructor(
    private db: AngularFirestore
  ) { }
  getBusinessForm() {
    const formFields = {
      businessName: new FormControl('', {
        validators: Validators.required
      }),
      businessType: new FormControl('',  {
        validators: Validators.required
      }),
      businessPhone: new FormControl('', {
        validators: Validators.required
      }),
      businessEmail: new FormControl(''),
      businessWebsite: new FormControl(''),
      resident: new FormControl('', {
        validators: Validators.required
      })
    };
    return new FormGroup(formFields);
  }
  getBusinesses() {
    this.businessCollection = this.db.collection('/businesses');
    this.businesses$ = this.businessCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Business;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    return this.businesses$;
  }
  getBus() {
    this.businessCollection = this.db.collection('/businesses');
    this.businesses$ = this.businessCollection.snapshotChanges().pipe(
      map(e => {
        return e.map(f => {
          const data = f.payload.doc.data() as Business;
          const id = f.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
  addNewBusiness(a: Business) {
    this.businessCollection = this.db.collection<Business>('/businesses');
    return this.businessCollection.add(a);
  }
  updateBusiness(a: string, b: Business) {
    this.businessDocument = this.db.doc('/businesses' + a);
    return this.businessDocument.update(b);
  }
  deleteBusiness(a: string) {
    this.businessDocument = this.db.doc('/businesses' + a);
    return this.businessDocument.delete();
  }
}
