import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { UsersService } from '../../../core/services/users.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  displayColumns: string[] = ['approve'];
  users: User[];
  users$: Observable<any>;
  constructor(
    private usersService: UsersService
  ) { }
  getUsers() {
    this.usersService.getAllUsers().pipe( takeUntil(this.unsubscribe$) )
      .subscribe((users: User[]) => {
        const abc = users.filter((a: User) => {
          return a.approved !== true;
        });
        this.users = abc;
      }, err => console.log(err), () => console.log('done'));
  }
  updateUser(id: string) {
    const obj = {
      approved: true
    };
    this.usersService.updateUser(id, obj);
  }
  ngOnInit() {
    this.getUsers();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
