import { Component, Input, OnInit } from '@angular/core';
import { Business } from '../../models/business';

@Component({
  selector: 'app-business-row',
  templateUrl: './business-row.component.html',
  styleUrls: ['./business-row.component.scss']
})
export class BusinessRowComponent implements OnInit {
  @Input() business: Business;
  constructor() { }

  ngOnInit(): void {
  }

}
