import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { HoaNavbarComponent } from './hoa-navbar/hoa-navbar.component';
import { environment } from 'src/environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { CoreModule } from './core/core.module';
import { BerkeleyComponent } from './berkeley/berkeley.component';
import { DirectoryModule } from './directory/directory.module';
import { BpaLogoComponent } from './bpa-logo/bpa-logo.component';

@NgModule({
  declarations: [
    AppComponent,
    HoaNavbarComponent,
    BerkeleyComponent,
    BpaLogoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    MaterialModule,
    CoreModule,
    DirectoryModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
