<div class="auth__page">

  <h1 class="auth__page-title">Login / Register</h1>

  <mat-card class="auth__page-body">
    <mat-card-title>How to get in!</mat-card-title>
    <mat-card-subtitle>
      <div>To login using your e-mail, Google, or Facebook credentials, click Login. You may also go straight to Login if you wish to register using your Google or Facebook credentials.</div>
      <br>
      <div>To register using your e-mail address, click Register using e-mail</div>
    </mat-card-subtitle>
    <mat-card-content>
      
    </mat-card-content>
    <mat-card-actions>
      <button mat-button color="accent" (click)="doLogin()">Login</button>
      <span>or</span>
      <button mat-button class="auth__page--register" (click)="doRegister()">Register using e-mail</button>
    </mat-card-actions>
  </mat-card>
  
</div>
  