import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { UsersService } from '../services/users.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovedGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userService: UsersService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authService.user
      .pipe(
        take(1),
        map(a => {
          if (!a.approved) {
            alert('Profile not yet approved');
            this.router.navigate(['/']);
            return false;
          } else {
            return true;
          }
        })
      );
    }
  
}
