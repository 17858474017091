import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-business',
  templateUrl: './new-business.component.html',
  styleUrls: ['./new-business.component.scss']
})
export class NewBusinessComponent implements OnInit {
  parent: FormGroup;
  residentId: string;
  constructor(
    public dialogRef: MatDialogRef<NewBusinessComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
  addNewBusiness() {
    this.dialogRef.close({
      newBusinessForm: this.parent
    });
  }
  setResidentId() {
    this.parent.patchValue({
      resident: this.residentId
    });
  }
  ngOnInit(): void {
    this.parent = this.data.newBusinessForm;
    this.residentId = this.data.user;
    this.setResidentId();
    console.log(this.residentId);
  }

}
