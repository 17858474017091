<div class="admin-row" *ngIf="admin">
    <div class="admin-row-displayname">
        {{ admin.displayName }}
    </div>
    <div class="admin-row-email">
        {{ admin.email }}
    </div>
    <div class="admin-row-actions">
        <button mat-raised-button color="accent" *ngIf="!admin.approved">Approve</button>
    </div>
</div>
