<div class="business__page">

    <div class="business__page-title">Business</div>
  
    <mat-divider></mat-divider>
  
    <div class="business__page-container">
      <mat-form-field class="business__page-form-field" appearance="fill" color="accent">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Enter text to search">
      </mat-form-field>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 business__page-table">

        <ng-container matColumnDef="business">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element">
            <app-business-row [business]="element"></app-business-row>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

      <mat-paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="dataSource.data.length"
        [pageSize]="pageSize"
      ></mat-paginator>

      <ng-container *ngIf="authService.user | async as user">
        <button *ngIf="user.resident" mat-raised-button color="accent" (click)="addNewBusiness(user.resident)">Add New</button>
      </ng-container>
    </div>
  
  
  </div>
  