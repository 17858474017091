<div class="directory">

  <div class="directory__title">Directory</div>
  
  <mat-divider></mat-divider>
  
  <div *ngIf="dataSource" class="directory__container">
    <mat-form-field appearance="fill" class="directory__filter-bar" color="accent">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Enter text to filter by Name or Address">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z9 directory__table">
      <ng-container matColumnDef="profile">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <app-directory-row
            [directory]="element"
            (resetProperty)="reset($event)"
            (claimProfile)="claimDirectory($event)"
            (updateProfile)="viewProfile($event)"
          ></app-directory-row>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [length]="length"
      [pageSize]="pageSize"
    ></mat-paginator>
  </div>

</div>
