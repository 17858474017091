import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Resident } from '../models/resident';

@Injectable({
  providedIn: 'root'
})
export class ResidentsService {
  private residentCollection: AngularFirestoreCollection<Resident>;
  private residentDocument: AngularFirestoreDocument<Resident>;
  residents$: Observable<Resident[]>;
  resident$: Observable<Resident>;
  constructor(
    private db: AngularFirestore
  ) { }
  getResidents() {
    this.residentCollection = this.db.collection<Resident>('residents');
    this.residents$ = this.residentCollection.valueChanges();
    return this.residents$;
  }
  getResident(a: string) {
    this.residentDocument = this.db.doc<Resident>(`residents/${a}`);
    this.resident$ = this.residentDocument.valueChanges();
    return this.resident$;
  }
  addResident(a: Resident) {
    this.residentCollection = this.db.collection('residents');
    return this.residentCollection.add(a);
  }
  getResidentForm() {
    const resident = {
      name1: new FormControl(''),
      name2: new FormControl(''),
      email1: new FormControl(''),
      email2: new FormControl(''),
      phone1: new FormControl(''),
      phone2: new FormControl(''),
      businessName: new FormControl(''),
      businessPhone: new FormControl(''),
      businessEmail: new FormControl(''),
      businessType: new FormControl(''),
      shareEmail: new FormControl(''),
      sharePhone: new FormControl(''),
      property: new FormControl(null),
      user: new FormControl(null),
    };
    return new FormGroup(resident);
  }
  getResidentId(userId: string, propertyId: string) {
    this.residentCollection = this.db.collection('residents', ref => ref
      .where('user', '==', userId)
      .where('property', '==', propertyId)
    );
    return this.residentCollection.snapshotChanges().pipe(
      take(1),
      map(e => {
        return e[0].payload.doc.id;
      })
    );
  }
  updateResident(userId: string, propertyId: string, updObj) {
    this.getResidentId(userId, propertyId).subscribe(e => {
      this.db.doc(`residents/${e}`).update(updObj)
        .then(f => alert('Updated'))
        .catch(err => console.log(err));
    });
  }
}
