import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {from, Observable} from 'rxjs';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  getAllUsers() {
    return this.db.collection('/users').valueChanges();
  }
  getNotApproved() {
    return this.db.collection('users', ref => ref.where('approved', '!=', true)).valueChanges();
  }
  updateUser(id: string, userObj) {
    return from(
      this.db.doc(`/users/${id}`).update(userObj)
        .then((ret) => {
          return ret;
        })
        .catch((error) => {
          console.log('Error updating: ', error);
        })
    );
  }
  getUser(id: string) {
    return this.db.collection(`/users/${id}`).valueChanges();
  }
  releaseResidence(id: string) {
    const resetObj = {
      claimedProperty: false,
      property: null
    };
    return this.db.doc(`/users/${id}`).update(resetObj);
  }
  constructor(
    private db: AngularFirestore
  ) { }
}
