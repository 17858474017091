import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<User>;
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    //// Get auth data, then get firestore user document || null
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }
  getUser() {
    return this.user.toPromise();
  }
  isLoggedIn() {
    return this.afAuth.authState;
  }
  reRouteProfile() {
    return this.router.navigate(['/directory']);
  }
  async emailLogin(email: string, password: string) {
    try {
      let user = await this.afAuth.signInWithEmailAndPassword(email, password);
      await this.updateUserData(user);
      return user;
    } catch (error) {
      return error;
    }
    // this.afAuth.signInWithEmailAndPassword(email, password)
    //   .then(e => {
    //     this.updateUserData(e.user)
    //       .then( () => {
    //         this.reRouteProfile();
    //       })
    //       .catch(err => {
    //         console.log(err);
    //       });
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
  }
  facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }
  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }
  emailRegister(a) {
    let recObj = a;
    this.afAuth.createUserWithEmailAndPassword(recObj.email, recObj.password)
      .then(e => {
        console.log(e);
      })
      .catch(err => {
        console.log(err);
      });
  }
  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((credential) => {
        this.updateUserData(credential.user)
          .then( () => { this.reRouteProfile(); })
          .catch( err => { console.log(err); });
      });
  }
  private updateUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data: User = {
      id: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL
    };
    return userRef.set(data, { merge: true });
  }
  signOut() {
    this.afAuth.signOut().then(() => {
      this.user = undefined;
      window.location.reload();
      this.router.navigate(['/']);
    });
  }
  getAuth() {
    return this.afAuth;
  }
  resetPasswordInit (email: string) {
    return this.afAuth.sendPasswordResetEmail(
      email,
      { url: 'http://localhost:4200/directory' }
    );
  }
}
/*
approved: true
displayName: null
email: "aaron@thewebfactory.io"
id: "hVcQDkg267aO3U69EaBsTFBIGgf2"
isAdmin: true
photoURL: null
uid: "hVcQDkg267aO3U69EaBsTFBIGgf2"
*/