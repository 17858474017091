import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'hoa-navbar',
  templateUrl: './hoa-navbar.component.html',
  styleUrls: ['./hoa-navbar.component.scss']
})
export class HoaNavbarComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  loggedIn: boolean;
  name: string = 'Berkeley';
  isAdmin = false;
  mobileNav: boolean;
  navList = [
    { key: 'Home', link: '/' },
    { key: 'Admin', link: '/admin' },
    { key: 'Directory', link: '/directory' },
    { key: 'Profile', link: '/resident' },
    { key: 'Businesses', link: '/business' }
  ];
  constructor(public authService: AuthService) { }
  checkLoggedIn() {
    this.authService.isLoggedIn()
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(e => {
        this.loggedIn = !!e;
      }, err => console.log(err),
        () => console.log('check login complete'));
  }
  mobileNavSwap() {
    this.mobileNav = !this.mobileNav;
  }
  signOut() {
    this.authService.signOut();
  }
  ngOnInit() {
    this.mobileNav = false;
    this.checkLoggedIn();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
