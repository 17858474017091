import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { noop } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../../components/login/login.component';
import { RegisterComponent } from '../../components/register/register.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
  user;
  registrationKey = environment.registrationKey;
  showRegForm = false;
  regKeyForm = new FormGroup({
    regKey: new FormControl('', {
      validators: Validators.required
    })
  });
  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl('')
  });
  registrationForm = new FormGroup({
    approved: new FormControl(false),
    isAdmin: new FormControl(false),
    claimedProperty: new FormControl(false),
    displayName: new FormControl('', {
      validators: Validators.required
    }),
    email: new FormControl('', {
      validators: Validators.required
    }),
    photoURL: new FormControl(''),
    property: new FormControl(null),
    resident: new FormControl(null),
    password: new FormControl('', {
      validators: Validators.required
    })
  });
  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    public router: Router
  ) { }
  regButton() {
    this.showRegForm = !this.showRegForm;
  }
  doLogin() {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '800px',
      height: '500px',
      panelClass: 'login-dialog-container',
      hasBackdrop: true,
      backdropClass: 'login-backdrop',
      data: { loginForm: this.loginForm }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.type === 'email') {
          await this.onLogin(result.loginForm.email, result.loginForm.password);
          this.router.navigate(['/directory']);
        }
        if (result.socialMedia === 'google') {
          await this.authService.googleLogin();
          this.router.navigate(['/directory']);
        }
        if (result.socialMedia === 'facebook') {
          await this.authService.facebookLogin();
          this.router.navigate(['/directory']);
        }
        if (result.type === 'reset') {
          await this.authService.resetPasswordInit(result.email);
          alert('Password reset instructions sent to your email');
        }
      }
    });
  }
  onSocialMedia(type: string) {
    if (type === 'google') {
      this.authService.googleLogin();
    }
    if (type === 'facebook') {
      this.authService.facebookLogin();
    }
  }
  async onLogin(email: string, password: string) {
    let ret = await this.authService.emailLogin(email, password);
    if (ret.code === 'auth/wrong-password') {
      alert(ret.message);
    }
  }
  doRegister() {
    // if (this.registrationKey !== this.regKeyForm.value.regKey) {
    //   alert('Incorrect Registration Key, please try again');
    //   return;
    // }
    const dialogRef = this.dialog.open(RegisterComponent, {
      data: { registrationForm: this.registrationForm }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authService.emailRegister(result.registrationForm);
      }
    });
  }
  ngOnInit(): void {
  }

}
