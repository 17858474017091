import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Directory } from '../models/directory';

@Injectable({
  providedIn: 'root'
})
export class DirectoryService {
  private directoryCollection: AngularFirestoreCollection<Directory>;
  directories$: Observable<Directory[]>;
  constructor(
    private db: AngularFirestore
  ) { }
  getDirectory(userId: string) {
    this.directoryCollection = this.db.collection<Directory>('directory', ref => ref.where('user.id', '==', userId));
    this.directories$ = this.directoryCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Directory;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    return this.directories$;
  }
  getProperties() {
    this.directoryCollection = this.db.collection<Directory>('directory');
    this.directories$ = this.directoryCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Directory;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    return this.directories$;
  }
  getDirectoryForm() {
    const directory = {
      streetNumber: new FormControl(''),
      streetName: new FormControl(''),
      city: new FormControl('Fort Worth'),
      state: new FormControl('TX'),
      zip: new FormControl('76110'),
      claimed: new FormControl(false),
      name1: new FormControl(''),
      name2: new FormControl(''),
      email1: new FormControl(''),
      email2: new FormControl(''),
      phone1: new FormControl(''),
      phone2: new FormControl(''),
      businessName: new FormControl(''),
      businessPhone: new FormControl(''),
      businessEmail: new FormControl(''),
      businessType: new FormControl(''),
      shareEmail: new FormControl(''),
      sharePhone: new FormControl(''),
      user: new FormControl(null)
    };
    return new FormGroup(directory);
  }
  updateDirectory(id: string, dir) {
    return this.db.doc(`directory/${id}`).update(dir);
  }
  resetDirectory(id: string) {
    const resetObj = {
      claimed: false,
      user: null
    };
    return this.db.doc(`directory/${id}`).update(resetObj);
  }
}
