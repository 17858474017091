import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { NewBusinessComponent } from './components/new-business/new-business.component';
import { ResidentProfileComponent } from './components/resident-profile/resident-profile.component';
import { ViewResidentProfileComponent } from './components/view-resident-profile/view-resident-profile.component';
import { BusinessComponent } from './containers/business/business.component';
import { DirectoryComponent } from './containers/directory/directory.component';
import { ResidentComponent } from './containers/resident/resident.component';
import { AdminComponent } from './containers/admin/admin.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectoryRowComponent } from './components/directory-row/directory-row.component';
import { BusinessRowComponent } from './components/business-row/business-row.component';
import { AdminRowComponent } from './components/admin-row/admin-row.component';



@NgModule({
  declarations: [
    NewBusinessComponent,
    ResidentProfileComponent,
    ViewResidentProfileComponent,
    BusinessComponent,
    NewBusinessComponent,
    DirectoryComponent,
    ResidentComponent,
    DirectoryRowComponent,
    BusinessRowComponent,
    AdminRowComponent,
    AdminComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule
  ]
})
export class DirectoryModule { }
