import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';
import { UsersService } from 'src/app/core/services/users.service';
import { ViewResidentProfileComponent } from '../../components/view-resident-profile/view-resident-profile.component';
import { Directory } from '../../models/directory';
import { DirectoryService } from '../../services/directory.service';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  directoryEntries$: Directory[];
  dataSource = new MatTableDataSource();
  user: User;
  // displayedColumns: string[] = ['name1', 'name2', 'streetNumber', 'streetName', 'viewProfile', 'claimProperty'];
  displayedColumns: string[] = ['profile'];
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  constructor(
    private directoryService: DirectoryService,
    private userService: UsersService,
    public authService: AuthService,
    public dialog: MatDialog
  ) { }
  getUser() {
    return this.authService.user
      .pipe(
        take(1)
      ).subscribe(e => {
        this.user = e;
      }, err => {
        console.log(err);
      }, () => {} );
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  viewProfile(a: Directory) {
    this.viewDirectoryEntry(a);
  }
  viewDirectoryEntry(a: Directory) {
    const dialogRef = this.dialog.open(ViewResidentProfileComponent, {
      width: '600px',
      panelClass: 'view-resident-profile',
      data: { directory: a, isAdmin: this.user.isAdmin }
    });
    dialogRef.afterClosed()
      .pipe(
        take(1)
      ).subscribe((e) => {
        if (e === undefined) {
          return;
        }
        if (e.action === 'release') {
          // this.resetProperty(e.directory.id, this.user.id);
        }
    }, err => console.log(err), () => console.log('done'));
  }
  getDirectoryEntries() {
    this.directoryService
      .getProperties()
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(e => {
          this.length = e.length;
          this.directoryEntries$ = e;
          this.directoryEntries$.map((f: Directory) => {
            f.address = `${f.streetNumber} ${f.streetName}`;
          });
          this.dataSource = new MatTableDataSource(this.directoryEntries$);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, err => { console.log(err); },
        () => { });
  }
  claimDirectory(a: Directory) {
    const retVal = confirm(`You are claiming ${a.streetNumber} ${a.streetName}. Are you sure this is correct?`);
    if (!retVal) {
      return;
    }
    this.userService.updateUser(this.user.id, {
      claimedProperty: true,
      property: a.id
    }).pipe(take(1)).subscribe(e => console.log(e), err => console.log(err), () => console.log('complete claim'));
    this.directoryService.updateDirectory(a.id, {
      claimed: true,
      user: this.user
    }).then(b => console.log(b)).catch(err => console.log(err));
  }
  tester(evt: any) {
    console.log(evt);
  }
  async reset(evt: any) {
    if (evt.property && evt.user) {
      try {
        let resetted = await this.directoryService.resetDirectory(evt.property);
        console.log(resetted);
        let dropped = await this.userService.releaseResidence(evt.user);
        console.log(dropped);
      } catch (error) {
        alert('Something went wrong...');
        console.log(error);
      }
    }
  }
  // resetProperty(a: string, b: string) {
  //   this.directoryService.resetDirectory(a)
  //     .then((e) => {
  //       alert('Property has been released');
  //     }).catch((err) => {
  //       alert('Something went wrong');
  //       console.log(err);
  //   });
  //   this.userService.releaseResidence(b)
  //     .then((f) => {
  //       console.log(f);
  //     }).catch((err2) => {
  //       console.log(err2);
  //   });
  // }
  ngOnInit() {
    this.getDirectoryEntries();
    this.getUser();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
