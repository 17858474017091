import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';
import { Directory } from '../../models/directory';
import { DirectoryService } from '../../services/directory.service';
import { ResidentsService } from '../../services/residents.service';

@Component({
  selector: 'app-resident',
  templateUrl: './resident.component.html',
  styleUrls: ['./resident.component.scss']
})
export class ResidentComponent implements OnInit {
  user: User;
  resident: Observable<Directory[]>;
  directoryId: string;
  residentForm = new FormGroup({
    businessEmail: new FormControl(''),
    businessName: new FormControl(''),
    businessPhone: new FormControl(''),
    businessType: new FormControl(''),
    email1: new FormControl(''),
    email2: new FormControl(''),
    name1: new FormControl(''),
    name2: new FormControl(''),
    phone1: new FormControl(''),
    phone2: new FormControl(''),
    shareEmail: new FormControl(false),
    sharePhone: new FormControl(false)
  });
  constructor(
    private residentsService: ResidentsService,
    private directoryService: DirectoryService,
    public authService: AuthService
  ) { }
  getUser() {
    this.authService.user.pipe(
      take(1),
      map(e => e )
    ).subscribe((f) => {
      this.user = f;
      this.resident = this.directoryService.getDirectory(this.user.id);
    }, err => console.log(err),
      () => {} );
  }
  setId(id: string) {
    this.directoryId = id;
  }
  updateDirectory(evt, res) {
    this.setId(res[0].id);
    this.directoryService.updateDirectory(this.directoryId, evt)
      .then(e => {
        alert(`Profile has been updated`);
      })
      .catch(err => {
        alert(`Something has gone wrong, ${err}`);
        console.log(err);
      });
  }
  ngOnInit() {
    this.getUser();
  }
}
