import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-berkeley',
  templateUrl: './berkeley.component.html',
  styleUrls: ['./berkeley.component.scss']
})
export class BerkeleyComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  loggedIn: boolean;
  constructor(
    public authService: AuthService
  ) { }
  checkLogin() {
    this.authService.isLoggedIn()
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(e => {
        this.loggedIn = !!e;
      }, err => {
        console.log(err);
      }, () => console.log('check login complete') );
  }
  ngOnInit(): void {
    this.checkLogin();
  }

}
