import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { NewBusinessComponent } from '../../components/new-business/new-business.component';
import { Business } from '../../models/business';
import { BusinessService } from '../../services/business.service';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  businessForm = new FormGroup({});
  businesses: Business[];
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'business'
  ];
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getBusinesses() {
    this.businessService.getBusinesses()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(e => {
        this.businesses = e;
        this.dataSource = new MatTableDataSource(this.businesses);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, err => console.log(err),
        () => { } );
  }
  addNewBusiness(user: string) {
    const dialogRef = this.dialog.open(NewBusinessComponent, {
      width: '600px',
      panelClass: 'new-business',
      data: { newBusinessForm: this.businessForm, user }
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.businessForm.patchValue(result.newBusinessForm.value);
          this.businessService.addNewBusiness(this.businessForm.value)
            .then(e => {
              console.log(e);
              this.businessForm.reset();
            })
            .catch(err => {
              console.log(err);
              this.businessForm.reset();
            });
        }
      });
  }
  constructor(
    private businessService: BusinessService,
    public dialog: MatDialog,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.businessForm = this.businessService.getBusinessForm();
    this.getBusinesses();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
