<div class="login-page">
  <div class="utility__container">

    <div class="login-page__leftside">
      <h1>Login</h1>
      <form [formGroup]="loginForm" class="login-form" (ngSubmit)="onSubmit()">

        <mat-form-field class="login-form__field" color="accent">
          <mat-label>E-mail</mat-label>
          <input matInput formControlName="email" type="text" autocomplete="username email">
        </mat-form-field>

        <mat-form-field class="login-form__field" color="accent">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" type="password" autocomplete="current-password">
        </mat-form-field>
        <button mat-raised-button color="accent" type="submit" class="button__login">Login</button>

      </form>
      <button mat-raised-button (click)="onSocialMedia('google')" class="button__google">Log in with Google</button>
      <button mat-raised-button (click)="onSocialMedia('facebook')"class="button__facebook">Log in with Facebook</button>
      <button mat-raised-button (click)="onReset()" class="button__reset">Reset Password</button>
    </div>
  
    <div class="login-page__rightside login-form__picture"></div>
  
  </div>
</div>