<div class="berkeley__page">

  <!-- <div class="berkeley__container">

    <div class="berkeley__col">
      <h1 class="berkeley__title">
        Welcome to the BPA Directory
      </h1>
    </div>

  </div>

  <mat-divider></mat-divider> -->

  <!-- <div class="berkeley__container berkeley__auth" *ngIf="!loggedIn">
    <div class="berkeley__col">
      <a routerLink="/auth" class="berkeley__link">ENTER</a>
      <p>click</p>
    </div>
  </div> -->

  <div class="berkeley__hero">
    <div class="berkeley__hero-left">
      <div class="berkeley__hero-left--top">
        neighborhood directory
      </div>
      <div class="berkeley__hero-left--body">
        <div class="berkeley__hero-berkeley">berkeley</div>
        <div class="berkeley__hero--flex berkeley__hero--flex-row">
          <div class="berkeley__hero-thin">___</div>
          <div class="berkeley__hero-place">place</div>
        </div>
        <div class="berkeley__hero-association">association</div>
      </div>
      <div class="berkeley__hero-left--bottom">
        <a mat-stroked-button href="https://www.berkeleyplace.org/" target="_blank" class="berkeley-link">berkeley website</a>
      </div>
    </div>
    <div class="berkeley__hero-right berkeley__hero--flex berkeley__hero--flex-row">
      <div class="hero-abstract berkeley__hero--flex berkeley__hero--flex-column">
        <div class="top-circle">
          <img src="../../assets/images/Asset 3-8.png" alt="Large Circle" class="large-circle">
        </div>
        <div class="middle-rect">
          <img src="../../assets/images/Asset 4-8.png" alt="Large Rect" class="large-rectangle">
        </div>
        <div class="bottom-square">
          <div class="gradient-square gradient-square-orange"></div>
        </div>
        <img src="../../assets/images/zeus.png" alt="Zeus" class="zeus">
      </div>
      <div class="hero-abstract berkeley__hero--flex berkeley__hero--flex-column">
        <div class="berkeley__hero--flex berkeley__hero--flex-column right-top">
          <div class="berkeley__hero--flex berkeley__hero--flex-row right-top-top">
            <div class="right-top-top-left">
              <img src="../../assets/images/Asset 5-8.png" alt="Small Square">
            </div>
            <div class="right-top-top-right">
              <img src="../../assets/images/Asset 8-8.png" alt="Half Circle Color">
            </div>
          </div>
          <div class="berkeley__hero--flex berkeley__hero--flex-row right-top-bottom">
            <div class="right-top-bottom-left">
              <img src="../../assets/images/Asset 7-8.png" alt="Large Square">
            </div>
            <div class="berkeley__hero--flex berkeley__hero--flex-column right-top-bottom-right">
              <div>
                <img src="../../assets/images/Asset 5-8.png" alt="Small Square">
              </div>
              <div>
                <img src="../../assets/images/small-circle-8.png" alt="Small Circle">
              </div>
            </div>
          </div>
        </div>
        <div class="berkeley__hero--flex berkeley__hero--flex-row right-bottom">
          <div class="berkeley__hero--flex berkeley__hero--flex-column right-bottom-left">
            <div>
              <img src="../../assets/images/Asset 9-8.png" alt="Half Circle">
            </div>
            <div>
              <img src="../../assets/images/small-circle-8.png" alt="Small Circle">
            </div>
          </div>
          <div class="right-bottom-right">
            <div class="gradient-square gradient-square-blue"></div>
            <img src="../../assets/images/apollo_bust.png" alt="Apollo Bust" style="height: 200px;" class="apollo">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="authService.user | async as user" class="berkeley__not-approved">
    <ng-container *ngIf="!user.approved">
      <h2>Your profile has not yet been approved</h2>
      <p>... profiles must first be approved by the site administrator</p>
      <p>before access is granted to the directory</p>
    </ng-container>
  </div>

</div>
  