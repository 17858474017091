<div class="admin__page">

  <h1 class="admin__page-title">
    Administration
  </h1>

  <div *ngIf="users">
    <table mat-table [dataSource]="users" class="mat-elevation-z8 admin__page-usertable">
      <ng-container matColumnDef="approve">
        <th mat-header-cell *matHeaderCellDef> Approve </th>
        <td mat-cell *matCellDef="let element">
          <app-admin-row [admin]="element" (approve)="updateUser($event)"></app-admin-row>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
    </table>
  </div>

</div>
  